import CollectionsOverlay from "@/components/CollectionsOverlay";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import MainMenu from "@/components/MainMenu";
import MaintenanceMode from "@/components/MaintenanceMode";
import Notifications from "@/components/Notifications";
import SalePrompt from "@/components/SalePrompt";
import SearchOverlay from "@/components/SearchOverlay";
import TimerList from "@/components/TimerList";
import { NotificationsContext } from "@/contexts/NotificationsContext";
import useApp from "@/hooks/useApp";
import useIsAuthPage from "@/hooks/useIsAuthPage";
import useIsHomepage from "@/hooks/useIsHomepage";
import { useStores } from "@/hooks/useStores";
import { pushEvent, pushPageView } from "@/lib/gtm";
import ListsOverlay from "@/src/lists/components/ListsOverlay";
import clsx from "clsx";
import { enableStaticRendering, observer } from "mobx-react";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";

const Layout = ({ children, notifications, navigation }) => {
  enableStaticRendering(typeof window === "undefined");

  const isMobApp = useApp();

  const router = useRouter();
  const { showNotification } = useContext(NotificationsContext);

  const { menuStore, authStore } = useStores();
  const [currencyFromQueryString, setCurrencyFromQueryString] = useState("");

  const ref = useRef(null);

  /*
   * Authentication and user data
   */

  const { currentUser } = authStore;

  /* Fetch the current user */
  useEffect(() => {
    authStore.fetchCurrentUser();
  }, [authStore]);

  /* Fetch the current user's menus */
  useEffect(() => {
    if (authStore.isLoggedIn && !currentUser.listsMigrated) {
      menuStore.fetch();
    }
  }, [authStore.isLoggedIn, currentUser?.listsMigrated, menuStore]);

  /*
   * Google Analytics
   */

  /* Register page view */
  useEffect(() => {
    const handleRouteChange = (url) => pushPageView(url);

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => router.events.off("routeChangeComplete", handleRouteChange);
  }, [router.events]);

  useEffect(() => {
    if (router.isReady && router.query["campaign_currency"]) {
      setCurrencyFromQueryString(router.query["campaign_currency"] as string);
    }
  }, [router.isReady]);

  /* Fire a loggedIn status event for ga */
  useEffect(() => {
    const status = authStore.isLoggedIn ? "loggedIn" : "guest";
    const userId = authStore.isLoggedIn ? currentUser.id : null;
    const data = {
      status,
      userId,
      planId: authStore.isLoggedIn ? currentUser.plan : 0,
    };
    pushEvent("loginStatus", data);
  }, [authStore, authStore.isLoggedIn]);

  const isAuthPage = useIsAuthPage();
  const isHomepage = useIsHomepage();

  /*
   * Auth modal
   */

  /* On route change, close down auth modals */
  useEffect(() => {
    const handleRouteChange = () => {
      authStore.setIsAuthActive(false);
      authStore.setIsClosable(true);
      authStore.setAuthStatus(null);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => router.events.off("routeChangeStart", handleRouteChange);
  }, [authStore, router.events]);

  useEffect(() => {
    // Change (--header-height) to the 0px if we're in the app
    if (isMobApp || isAuthPage) {
      document.documentElement.style.setProperty("--header-height", "0px");
    }
  }, [isMobApp, isAuthPage]);

  /*
   * Maintenance mode
   */

  if (
    process.env.NEXT_PUBLIC_MAINTENANCE_MODE === "on" &&
    !(
      authStore.isLoggedIn &&
      (authStore.currentUser.id === "1" ||
        authStore.currentUser.id === "3687381")
    )
  )
    return <MaintenanceMode />;

  return (
    <>
      <div className={clsx("Layout", "print:!pb-0")} ref={ref}>
        {/* TODO Disable notifications for now, since layout changes mean this won't work  */}
        {/*
        {!isAuthPage && !isMobApp && (
          <div className={clsx("Layout__notifications", "print:hidden")}>
            <Notifications initialNotifications={notifications} />
          </div>
        )}
				*/}

        {!isAuthPage && !isMobApp && (
          <Header displayCurrency={currencyFromQueryString} />
        )}

        <div
          className={clsx("Layout__content", "relative z-20", {
            "mt-12 print:!mt-0": showNotification && !isMobApp,
            "mt-0": !showNotification || isMobApp,
            "pt-30 lg:pt-20 2xl:pt-24": !isAuthPage && !isHomepage,
            "pt-16 lg:mt-px lg:pt-20 2xl:pt-24": isHomepage,
          })}
        >
          {!isAuthPage && !isMobApp && (
            <SalePrompt displayCurrency={currencyFromQueryString} />
          )}
          <main
            className={clsx(
              "Layout__main",
              "min-h-dvh-ex-header relative z-10 flex h-full flex-col bg-white"
            )}
          >
            {children}
          </main>
        </div>

        {!isAuthPage && !isMobApp && (
          <div className={clsx("Layout__footer", "")}>
            <Footer />
          </div>
        )}
      </div>
      <TimerList />

      {!currentUser?.listsMigrated && <CollectionsOverlay />}

      {currentUser?.listsMigrated && <ListsOverlay />}

      <SearchOverlay theme={"light"} />

      <MainMenu items={navigation?.mainMenu} />
    </>
  );
};

Layout.displayName = "Layout";

export default observer(Layout);
