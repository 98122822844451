import useCurrentUser from "@/hooks/useCurrentUser";
import { DEFAULT_SEARCH_CHARACTER } from "@/lib/algoliaSearchClient";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useInstantSearch } from "react-instantsearch";
import {
  INDEX_LATEST_DESC,
  SUGGESTIONS_INDEX,
} from "@/constants/algoliaConstants";

const useSetAlgoliaDefaults = ({ enabled = true }) => {
  const router = useRouter();

  // Fetch the current user
  const { currentUser, isLoggedOut } = useCurrentUser();
  const { setUiState } = useInstantSearch();

  const currentUserDietaryRequirements = useMemo(() => {
    return currentUser?.dietaryRequirements?.map((d) => d.title);
  }, [currentUser]);

  // To avoid sending unnecessary requests to Algolia, we set it to a dummy character, which is prevented from being sent to Algolia in the algoliaClient
  let initialQuery = DEFAULT_SEARCH_CHARACTER;

  // We need to set the initial query to an empty string if we are on the search page, since we want results right away
  if (router.pathname === "/search") {
    initialQuery = "";
  }

  useEffect(() => {
    if (!enabled || isLoggedOut) return;

    const indexName = INDEX_LATEST_DESC;
    const suggestionsIndexName = SUGGESTIONS_INDEX;

    setUiState((prevUiState) => ({
      ...prevUiState,
      [indexName]: {
        ...prevUiState[indexName]?.refinementList,
        sortBy: `${indexName}`,
        refinementList: {
          ...prevUiState[indexName]?.refinementList,
          "dietaryRequirements.title": currentUserDietaryRequirements,
        },
        menu: {
          ...prevUiState[indexName]?.menu,
        },
        query: initialQuery,
      },
      [suggestionsIndexName]: {
        ...prevUiState[indexName]?.refinementList,
        refinementList: {
          ...prevUiState[indexName]?.refinementList,
          "dietaryRequirements.title": currentUserDietaryRequirements,
        },
      },
    }));
  }, [enabled, isLoggedOut, currentUserDietaryRequirements, setUiState]);
};

export default useSetAlgoliaDefaults;
