export const sortLabels = [{ label: "Latest" }, { label: "Popular" }];

export const getAlgoliaIndexName = (userPlan, sortByLabel = "Latest") => {
  const isFreePlan = userPlan === 0 || userPlan === 1 || userPlan === 3;

  const labelToIndexSuffix = {
    Latest: "latest",
    Popular: "popularity",
  };

  const indexSuffix = labelToIndexSuffix[sortByLabel] || "latest";

  const indexName = isFreePlan
    ? `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_${indexSuffix}_desc_free`
    : `${process.env.NEXT_PUBLIC_ALGOLIA_ENTRY_INDEX_NAME}_${indexSuffix}_desc`;

  return indexName;
};

export const sortByItems = (userPlan) => {
  return sortLabels.map((item) => ({
    label: item.label,
    value: getAlgoliaIndexName(userPlan, item.label),
  }));
};
