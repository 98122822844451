import { fetcher } from "@/lib/algoliaUtils";
import clsx from "clsx";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { observer } from "mobx-react";
import { useInView } from "react-intersection-observer";
import useSWR from "swr";
import { INDEX_POPULARITY_DESC } from "@/constants/algoliaConstants";

// Use the timezone plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const AlgoliaDataWrapper = ({
  onlyFetchWhenInView = true,
  optionalFilters = undefined,
  query = undefined,
  filters = "",
  facets = ["*"],
  render,
  renderLoading,
  facetFilters = undefined,
  tagFilters = undefined,
  shouldFetch = true,
  indexName = INDEX_POPULARITY_DESC,
  postDateInPast = true,
  postDateInFuture = false,
  hitsPerPage = 12,
  numericFilters = [],
  responseFields = ["hits"],
  attributesToRetrieve = [
    "id",
    "uid",
    "slug",
    "uri",
    "title",
    "image",
    "sectionHandle",
    "chefs",
    "accessLevel",
    "dietaryRequirements",
    "time",
    "recipeCount",
    "postDate",
  ],
  attributesToHighlight = [],
  getRankingInfo = true,
  shuffleSeed = -1,
  entriesToShow = 12,
}: {
  onlyFetchWhenInView?: boolean;
  query?: string;
  filters?: string;
  facets?: string[];
  optionalFilters?: string[];
  render: (data: object[]) => JSX.Element;
  renderLoading?: () => JSX.Element;
  facetFilters?: string[] | string[][];
  shouldFetch?: boolean;
  tagFilters?: string[];
  indexName?: string;
  postDateInPast?: boolean;
  postDateInFuture?: boolean;
  hitsPerPage?: number;
  numericFilters?: string[];
  responseFields?: string[];
  attributesToRetrieve?: string[];
  attributesToHighlight?: string[];
  getRankingInfo?: boolean;
  shuffleSeed?: number; // to enabled daily, stable shuffling of results
  entriesToShow?: number; // to slice the results to the number of entries to show
}) => {
  // Set the timezone to London and get the start of the day
  const now = dayjs().tz("Europe/London").startOf("hour").valueOf();

  // Setup the in-view hook
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    rootMargin: "-200px 0px 0px 0px", // Make it trigger 200px before the user gets to it to make it seem faster
  });

  // Create a new Set from numericFilters to ensure uniqueness
  const uniqueNumericFilters = new Set(numericFilters);

  if (postDateInPast) {
    uniqueNumericFilters.add(`postDate<=${now}`);
  }

  if (postDateInFuture) {
    uniqueNumericFilters.add(`postDate>${now}`);
  }

  // Convert the Set back to an array
  const finalNumericFilters = Array.from(uniqueNumericFilters);

  // Determine whether we really should fetch, given the props
  const reallyShouldFetch =
    shouldFetch && (!onlyFetchWhenInView || (onlyFetchWhenInView && inView));

  const { data, error } = useSWR(
    reallyShouldFetch
      ? {
          indexName,
          query,
          filters,
          tagFilters,
          facets,
          facetFilters,
          hitsPerPage,
          numericFilters: finalNumericFilters,
          responseFields,
          optionalFilters,
          attributesToRetrieve,
          attributesToHighlight,
          getRankingInfo,
          shuffleSeed,
          entriesToShow,
        }
      : null,
    fetcher
  );

  // Conditional rendering based on the data, error, and loading states
  let content;
  if (error) {
    content = (
      <div className={clsx("flex w-full items-center justify-center")}>
        Error in search
      </div>
    );
  } else if (!data) {
    if (renderLoading) {
      content = renderLoading();
    } else {
      content = (
        <div className={clsx("flex w-full items-center justify-center p-32")}>
          Loading...
        </div>
      );
    }
  } else {
    content = render(data);
  }

  // Wrap your content with the ref assigned
  return <div ref={ref}>{content}</div>;
};

export default observer(AlgoliaDataWrapper);
